import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Category } from "../pages/HelpCenter/HelpCenter";

interface HelpCenterState {
  categories: { mainCategory: Category[]; somethingElseCategory: Category[] };
}

const initialState: HelpCenterState = {
  categories: {
    mainCategory: [],
    somethingElseCategory: [],
  },
};

const helpCenterSlice = createSlice({
  name: "helpCenter",
  initialState,
  reducers: {
    setCategories(state, action: PayloadAction<Category[]>) {
      const mainCategory: Category[] = [];
      const somethingElseCategory: Category[] = [];
      action?.payload?.forEach((c) => {
        if (c.card_type === 1) {
          mainCategory.push(c);
        }
        if (c.card_type === 2) {
          somethingElseCategory.push(c);
        }
      });

      state.categories = { somethingElseCategory, mainCategory };
    },
  },
});

export const { setCategories } = helpCenterSlice.actions;
export default helpCenterSlice.reducer;

export const selectCategories = (state: { helpCenter: HelpCenterState }) => {
  return state?.helpCenter?.categories;
};
